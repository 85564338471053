<template>
  <div class="contact">
      <u>sign up for our mailing list below</u>
    <br><form action="https://send.pageclip.co/CyovvA9bTEEmMRTEKXpH4HLI5ILPUYnh" class="pageclip-form form" method="post">
        Name: <input type="text" name="name" style="margin: .25vw 0; color:white;" value="" />
        <br>Email: <input type="email" name="email" style="margin: .25vw 0; color:white;" value="" />
        <br><br><button type="submit" class="pageclip-form__submit button">
    <span>Send</span>
  </button>
</form>
  </div>
</template>

<script>
export default {
  name: 'contact',

}
</script>

<style scoped>
.form{
    margin: 2vh 0;
}
.button{
  color: greenyellow;
    background-color: #141313;
    border: none;
    padding: .5vw;
}
.button:hover{
    color: #141313;
    background-color: greenyellow;
}
</style>
